import React from 'react';
// import './contactForm.css';
const Contact = () => {
  return (
    <iframe
      title='contact-form'
      src='https://docs.google.com/forms/d/e/1FAIpQLScRpphUM09GNg7S23lqzl4oOgZNVAkSkfK1C-BkSBuiJGskkA/viewform?embedded=true'
      scrolling='no'
    >
      Loading…
    </iframe>
  );
};

export default Contact;
