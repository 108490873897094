import coupleImage from '../../assets/images/Sample Images/gallery 1.jpg';
import coupleImage2 from '../../assets/images/Sample Images/gallery 2.jpg';
import familyImage from '../../assets/images/Sample Images/gallery 3.jpg';
import familyImage2 from '../../assets/images/Sample Images/gallery 4.jpg';
import hour3Image from '../../assets/images/Sample Images/Portrait.jpg';
import hour3Image2 from '../../assets/images/Sample Images/Portrait2.jpg';

export const promoData = [
  {
    image: coupleImage,
    title: 'Couple Photoshoot',
    subtitle: 'Studio/1hr',
    detail1: 'Includes 2 people',
    detail2: '30 Images in total',
    detail3: '15 retouched images ',
    price: 550,
    distance: 0,
  },
  {
    image: coupleImage2,
    title: 'Couple Photoshoot',
    subtitle: 'On-Location/1hr',
    detail1: 'Includes 2 people',
    detail2: '30 Images in total',
    detail3: '5 retouched images ',
    price: 650,
    distance: 0,
  },
  {
    image: familyImage,
    title: 'Family Photoshoot',
    subtitle: 'Studio/1hr',
    detail1: 'Includes 4 people',
    detail2: '100 Images in total',
    detail3: '50 retouched images ',
    price: 550,
    distance: 0,
  },
  {
    image: familyImage2,
    title: 'Family Photoshoot',
    subtitle: 'On-Location/1hr',
    detail1: 'Includes 4 people',
    detail2: '100 Images in total',
    detail3: '50 retouched images ',
    price: 650,
    distance: 0,
  },
  {
    image: hour3Image,
    title: '3hr Studio Photoshoot',
    subtitle: 'Studio/3hr',
    detail1: 'Includes 4 people',
    detail2: '100 Images in total',
    detail3: '50 retouched images ',
    price: 1500,
    distance: 0,
  },
  {
    image: hour3Image2,
    title: '3Hr On-Location Photoshoot',
    subtitle: 'On-Location/3hr',
    detail1: 'Includes 4 people',
    detail2: '100 Images in total',
    detail3: '50 retouched images ',
    price: 1750,
    distance: 0,
  },
];
